import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InputForm.css';


function InputForm({setPositionSize, setMarginBalance, setLeverage, setRiskRewardRatio, setPnlLoss, setPnlGain}) {
  const [accountSize, setAccountSize] = useState('');
  const [accountRisk, setAccountRisk] = useState('');
  const [selectedPair, setSelectedPair] = useState('');
  const [exchange, setExchange] = useState('Binance');
  const [binancePairs, setBinancePairs] = useState([]);
  const [bybitPairs, setBybitPairs] = useState([]);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);

  const [stopLoss, setStopLoss] = useState('');
  const [stopLossType, setStopLossType] = useState('USDT');
  const [takeProfit, setTakeProfit] = useState('');
  const [takeProfitType, setTakeProfitType] = useState('USDT');
  const [entryPrice, setEntryPrice] = useState('');

  const fetchLastPrice = async () => {
    setIsLoadingPrice(true);
    try {
      let response;
      let lastPrice;
      if (exchange === 'Binance') {
        response = await axios.get(`https://fapi.binance.com/fapi/v1/ticker/price?symbol=${selectedPair}`);
        lastPrice = response.data.price;
      } else if (exchange === 'Bybit') {
        response = await axios.get(`https://api.bybit.com/v5/market/tickers?category=linear&symbol=${selectedPair}`);
        if (response.data.result.list.length > 0) {
          lastPrice = response.data.result.list[0].lastPrice;
        }
        console.log(response.data);
      }
      setEntryPrice(lastPrice || '');
    } catch (error) {
      console.error('Error fetching last price:', error);
    }
    setIsLoadingPrice(false);
  };

  useEffect(() => {
    setEntryPrice('');
  }, [selectedPair, exchange]);

  useEffect(() => {
    setSelectedPair(exchange === 'Binance' ? binancePairs[0] : bybitPairs[0]);
  }, [exchange, binancePairs, bybitPairs]);


  useEffect(() => {
    // Fetch Binance pairs
    axios.get('https://fapi.binance.com/fapi/v1/exchangeInfo')
        .then(response => {
            const pairs = response.data.symbols.map(symbolInfo => symbolInfo.symbol);
            setBinancePairs(pairs);
        })
        .catch(error => {
            console.error('Error fetching Binance pairs:', error);
        });

    // Fetch Bybit pairs
    axios.get('https://api.bybit.com/v5/market/instruments-info?category=linear')
        .then(response => {
          const symbols = response.data.result.list.map((contract) => contract.symbol);
          setBybitPairs(symbols);
        })
        .catch(error => {
            console.error('Error fetching Bybit pairs:', error);
        });
}, []);



  const handleSubmit = (event) => {
    event.preventDefault();
    // Conversion des valeurs du formulaire en nombres
    const entryPriceNum = parseFloat(entryPrice);
    const accountSizeNum = parseFloat(accountSize);
    const accountRiskNum = parseFloat(accountRisk);
    
    
    let stopLossNum = parseFloat(stopLoss);
    if (stopLossType === "%") {
      stopLossNum = entryPriceNum - (entryPriceNum * (stopLossNum / 100));
    }

    // Déterminer si l'ordre est long ou short
    const isLong = stopLossNum < entryPriceNum;
    
    let takeProfitNum = parseFloat(takeProfit);
    if (takeProfitType === "%") {
      takeProfitNum = isLong ? entryPriceNum + (entryPriceNum * (takeProfitNum / 100)) : entryPriceNum - (entryPriceNum * (takeProfitNum / 100));
    }

    const accountRiskValue = accountSizeNum * accountRiskNum / 100;

    // Calcul des valeurs de sortie

    const positionSizeCalcRaw = isLong ? accountRiskValue / (entryPriceNum - stopLossNum) : accountRiskValue / (stopLossNum - entryPriceNum);
    const positionSizeCalc = positionSizeCalcRaw < 0.001 ? +(positionSizeCalcRaw.toPrecision(3)) : +(positionSizeCalcRaw.toFixed(3));
    setPositionSize(Number.isFinite(positionSizeCalc) ? positionSizeCalc : "--");
    
    const leverageCalcRaw = ((positionSizeCalc * entryPriceNum) / accountRiskValue) * 0.8;
    const leverageCalc = Math.round(leverageCalcRaw);
    setLeverage(Number.isFinite(leverageCalc) ? leverageCalc : "--");
    
    const marginBalanceCalcRaw = positionSizeCalc * entryPriceNum / leverageCalc;
    const marginBalanceCalc = +(marginBalanceCalcRaw.toFixed(2));
    setMarginBalance(Number.isFinite(marginBalanceCalc) ? marginBalanceCalc : "--");
    
    const pnlLossCalcRaw = positionSizeCalc * (isLong ? (entryPriceNum - stopLossNum) : (stopLossNum - entryPriceNum));
    const pnlLossCalc = +(pnlLossCalcRaw.toFixed(2));
    setPnlLoss(Number.isFinite(pnlLossCalc) ? pnlLossCalc : "--");
    
    let pnlGainCalc;
    if (isLong) {
      pnlGainCalc = takeProfitNum < entryPriceNum ? positionSizeCalc * (entryPriceNum - takeProfitNum) : positionSizeCalc * (takeProfitNum - entryPriceNum);
    } else {
      pnlGainCalc = positionSizeCalc * (entryPriceNum - takeProfitNum);
    }
    setPnlGain(Number.isFinite(pnlGainCalc) ? pnlGainCalc.toFixed(2) : "--");
    
    
    const riskRewardRatioCalcRaw = pnlGainCalc / pnlLossCalc;
    const riskRewardRatioCalc = +(riskRewardRatioCalcRaw.toFixed(2));
    setRiskRewardRatio(Number.isFinite(riskRewardRatioCalc) ? riskRewardRatioCalc : "--");



    /*console.log('Taille du compte:', accountSize);
    console.log('Risque du compte:', accountRisk);
    console.log('Paire sélectionnée:', selectedPair);
    console.log('Stop loss:', stopLoss, stopLossType);
    console.log('Take profit:', takeProfit, takeProfitType);
    console.log('Prix d\'entrée:', entryPrice);
    console.log('Taille de position:', positionSizeCalc);
    console.log('Solde de marge:', marginBalanceCalc);
    console.log('Effet de levier:', leverageCalc);
    console.log('PnL Loss:', pnlLossCalc);
    console.log('PnL Gain:', pnlGainCalc);
    console.log('Ratio risque/récompense:', riskRewardRatioCalc);
    console.log('SL : ', stopLossNum);
    console.log('TP : ', takeProfitNum);*/
  };

  return (
    <div className="container d-flex justify-content-center">
    <form onSubmit={handleSubmit} className="form-group" style={{ maxWidth: '2000px' }}>
    <div className="row">
      <div className="col-md-6 d-flex align-items-stretch">
    <div className="card mb-3 p-3 w-100 fixed-card" style={{ minWidth: '200px' }}>
        <h5 className="card-title">Account</h5>
        <div className="mb-3">
            <label className="form-label">Account size (USDT) :</label>
            <input type="number" value={accountSize} onChange={e => setAccountSize(e.target.value)} className="form-control" />
        </div>
        <div className="mb-3">
            <label className="form-label">Account risk (%) :</label>
            <input type="number" value={accountRisk} onChange={e => setAccountRisk(e.target.value)} className="form-control" />
        </div>
    </div>
    
    </div>
    <div className="col-md-6 d-flex align-items-stretch">
    <div className="card mb-3 p-3 w-100 fixed-card" style={{ minWidt: '200px' }}>
        <h5 className="card-title">Exchange / Crypto</h5>
        <div className="mb-3">
            <label className="form-label">Select exchange :</label>
            <select value={exchange} onChange={e => setExchange(e.target.value)} className="form-select">
                <option value="Binance">Binance</option>
                <option value="Bybit">Bybit</option>
            </select>
        </div>
        <div className="mb-3">
            <label className="form-label">Select crypto :</label>
            <select value={selectedPair} onChange={e => setSelectedPair(e.target.value)} className="form-select">
                {exchange === 'Binance' ? binancePairs.map(pair => (
                    <option key={pair} value={pair}>{pair}</option>
                )) : bybitPairs.map(symbol => (
                    <option key={symbol} value={symbol}>{symbol}</option>
                ))}
            </select>
        </div>
    </div>
    </div>
    </div> {/* fin de la première rangée */}
    <div className="row">
      <div className="col-12">
    <div className="card mb-3 p-3">
  <h5 className="card-title">Trade details</h5>
  <div className="mb-3">
    <label className="form-label">Stop loss :</label>
    <input type="number" value={stopLoss} onChange={e => setStopLoss(e.target.value)} className="form-control" />
    <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" value="USDT" checked={stopLossType === 'USDT'} onChange={e => setStopLossType(e.target.value)} />
      <label className="form-check-label">USDT</label>
    </div>
    <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" value="%" checked={stopLossType === '%'} onChange={e => setStopLossType(e.target.value)} />
      <label className="form-check-label">%</label>
    </div>
  </div>
  <div className="mb-3">
    <label className="form-label">Take profit :</label>
    <input type="number" value={takeProfit} onChange={e => setTakeProfit(e.target.value)} className="form-control" />
    <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" value="USDT" checked={takeProfitType === 'USDT'} onChange={e => setTakeProfitType(e.target.value)} />
      <label className="form-check-label">USDT</label>
    </div>
    <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" value="%" checked={takeProfitType === '%'} onChange={e => setTakeProfitType(e.target.value)} />
      <label className="form-check-label">%</label>
    </div>
  </div>
  <div className="mb-3">
    <label className="form-label">Entry price :</label>
    <input type="number" value={entryPrice} onChange={e => setEntryPrice(e.target.value)} className="form-control" />
  </div>
  <div className="mb-3">
    <button type="button" onClick={fetchLastPrice} disabled={isLoadingPrice || !selectedPair} className="btn btn-primary btn-sm">
      {isLoadingPrice ? 'Loading...' : 'Last Price'}
    </button>
  </div>
</div>
</div>
</div>
<div className="row">
      <div className="col-12 d-flex justify-content-center">
        <button type="submit" className="btn btn-success" style={{ 
          backgroundColor: exchange === 'Binance' ? '#f3ba2f' : exchange === 'Bybit' ? '#F7A600' : '#23AF91',
          borderColor: exchange === 'Binance' ? '#f3ba2f' : exchange === 'Bybit' ? '#F7A600' : '#23AF91',
          color: exchange === 'Bybit' ? '#17181E' : 'white' }}>Calculate</button>
    </div>
    </div>
    </form>
    </div>
);
}

export default InputForm;

