import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
    <div className="footer-container">
        <div className="footer-left">
            <h2>positionsizecalculator.xyz</h2>
        </div>
        <div className="footer-right">
            <p>
                positionsizecalculator.xyz is not affiliated with any of the companies, whose names are used on this website as references. 
                positionsizecalculator.xyz is free to use and cannot guarantee that the calculator will give the desired result or any other result. 
                By using positionsizecalculator.xyz, you agree not to use this website in any manner not intended, such as illegal activities or 
                activities that infringe upon third parties. Therefore, you agree not to hold positionsizecalculator.xyz or its creator liable for 
                any liability whatsoever.
            </p>
        </div>
    </div>
</footer>
  );
}

export default Footer;
