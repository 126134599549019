import React, { useState } from 'react';
import Header from './Header';
import Introduction from './Introduction';
import InputForm from './InputForm';
import OutputForm from './OutputForm';
import FAQ from './FAQ';
import Footer from './Footer';
import './App.css';


function App() {
  
  // Declare state for output values
  const [positionSize, setPositionSize] = useState(0);
  const [leverage, setLeverage] = useState(0);
  const [marginBalance, setMarginBalance] = useState(0);
  const [pnlLoss, setPnlLoss] = useState(0);
  const [pnlGain, setPnlGain] = useState(0);
  const [riskRewardRatio, setRiskRewardRatio] = useState(0);

  return (
    <div className="App">
      <div className="header-container">
        <Header />
      </div>
          <Introduction />
      <div id="input-form" className="input-form-container mb-5">
        <InputForm
          setPositionSize={setPositionSize}
          setMarginBalance={setMarginBalance}
          setLeverage={setLeverage}
          setRiskRewardRatio={setRiskRewardRatio}
          setPnlLoss={setPnlLoss}
          setPnlGain={setPnlGain}
        />
      </div>
      <div className="output-form-container">
        <OutputForm 
          positionSize={positionSize} 
          leverage={leverage} 
          marginBalance={marginBalance} 
          pnlLoss={pnlLoss} 
          pnlGain={pnlGain} 
          riskRewardRatio={riskRewardRatio} 
        />
      </div>
      <div id="faq" className="faq-container">
        <FAQ />
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
}

export default App;



