import React from 'react';
import './Introduction.css';

function Introduction() {
  return (
    <div className="introduction-container">
      <h1>Position Size Calculator for Crypto Trading</h1>
      <p>
        Manage risk in crypto trading on Binance, Bybit and more. 
        Enter your trading parameters and let our calculator provide you with 
        your optimal position size, leverage, margin cost, potential profit and 
        loss, and risk/reward ratio.
      </p>
    </div>
  );
}

export default Introduction;
