import React from 'react';
import './Accordion.css';

function AccordionItem({ title, children, isOpen, onClick }) {
  return (
    <div className="accordion-item" onClick={onClick}>
      <div className="accordion-title">
        {title}
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
}

export default AccordionItem;
