import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const data = [
    { title: 'What is a Position Size Calculator?', content: 'The Position Size Calculator is an easy-to-use online tool that helps you calculate the optimal position size for your crypto futures trades on all exchanges.' },
    { title: 'How do I use the Position Size Calculator?', content: "To use the Position Size Calculator, enter your Account Size, Account Risk, the Exchange you're using, the Crypto Pair you're trading, your Stop Loss, Take Profit, and Entry Price. Click the Last Price button to automatically fill the entry price with the last price. Click Calculate to get your results." },
    { title: 'What does the "Last Price" button do?', content: 'The "Last Price" button automatically matches your entry price to the last price of the selected crypto pair on the selected exchange.' },
    { title: 'What results will the Position Size Calculator give me?', content: 'The Position Size Calculator will provide your Position Size, Leverage, Margin Cost, Potential Loss (PnL Loss), Potential Gain (PnL Gain), and Risk/Reward Ratio.' },
    { title: 'What is Position Size?', content: "Position size is the number of units invested in a particular security/crypto by an investor or trader. It's important to calculate your position size accurately to manage risk effectively." },
    { title: 'What is Leverage in crypto futures trading?', content: "Leverage in crypto futures trading refers to the use of borrowed funds to increase potential returns. It's a double-edged sword, though, as it can also magnify losses." },
    { title: 'What is Margin Cost?', content: "Margin cost is the amount of equity traders need to hold in their accounts to keep their positions open. It's determined by the size of your position and the leverage used." },
    { title: 'What is Risk/Reward Ratio?', content: 'The Risk/Reward Ratio is a measure used by many traders to compare the expected returns of an investment to the amount of risk undertaken to capture these returns.' },
    { title: 'What are PnL (Loss) and PnL (Gain)?', content: 'PnL stands for Profit and Loss. PnL (Loss) indicates the amount you stand to lose if your stop loss is hit, and PnL (Gain) indicates your potential profit if your take profit is hit.' },
    { title: 'Can I use this tool for exchanges other than Binance and Bybit?', content: 'Yes ! Currently, our Position Size Calculator is optimized for Binance and Bybit. However, the principles are generally applicable to other exchanges as well.' },
  ];

  return (
    <div className="accordion-container">  {/* Ajouter cette classe CSS */}
      {data.map((item, index) => (
        <AccordionItem
          isOpen={index === openIndex}
          onClick={() => setOpenIndex(index)}
          title={item.title}
          key={index}
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  );
}

export default FAQ;
