import React from 'react';
import { Link } from 'react-scroll';
import './Header.css';

const Header = () => (
  <header className="header">
    <div className="header-logo">
      PSC
    </div>
    <nav className="header-nav">
      <Link to="input-form" spy smooth duration={500}>Calculator</Link>
      <Link to="faq" spy smooth duration={500}>FAQ</Link>
    </nav>
  </header>
);

export default Header;
