import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';


function OutputForm({positionSize, leverage, marginBalance, pnlLoss, pnlGain, riskRewardRatio}) {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // réinitialise 'copied' après 2 secondes
  };
  return (
    <div className="container d-flex justify-content-center">
      <div className="card p-3 mb-3" style={{ maxWidth: '2000px', minWidth: '220px' }}>
        <h2 className="card-title">Your trade</h2>
        <p className="mb-2">
          <strong>Position size :</strong>
          <span className="badge bg-primary">{positionSize}</span>
          <CopyToClipboard text={positionSize}
            onCopy={handleCopy}>
            <button type="button" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <i className={`fa${copied ? 's' : 'r'} fa-${copied ? 'check' : 'copy'}`} style={{ fontSize: '1rem', verticalAlign: 'middle' }}></i>
            </button>
          </CopyToClipboard>
        </p>
        <p className="mb-2"><strong>Leverage :</strong> <span className="badge bg-warning">{leverage}</span></p>
        <p className="mb-2"><strong>Margin cost :</strong> <span className="badge bg-secondary">{marginBalance}</span></p>
        <p className="mb-2"><strong>PnL (loss) :</strong> <span className="badge bg-danger">{pnlLoss}</span></p>
        <p className="mb-2"><strong>PnL (gain) :</strong> <span className="badge bg-success">{pnlGain}</span></p>
        <p className="mb-2"><strong>Risk Reward Ratio :</strong> <span className="badge bg-info">{riskRewardRatio}</span></p>
      </div>
    </div>
  );
}

export default OutputForm;

